import React from "react"

import Lottie from "lottie-react"

import { Toast, Row } from "react-bootstrap"

import CrossAnimation from "@/toasts/error-cross.json"

function ErrorToast(props) {
    return (
        <>
            {/* Error Toast */}

            <Toast
                className="toast-response shadow-lg"
                // Show toast when error in form submit, reset form
                // state when toast closes.
                show={props.status === "error" ? true : false}
                onClose={props.resetModal}
                delay={3000}
                autohide
                animation
                style={{
                    borderStyle: "solid",
                    borderColor: "#e0e1e2",
                    borderWidth: "0.1rem",
                }}
            >
                <Toast.Header style={{ backgroundColor: "#DF8686" }}>
                    <strong className="me-auto"></strong>
                </Toast.Header>
                <Toast.Body className="px-6 pb-4">
                    <Row className="justify-content-center">
                        <Lottie
                            animationData={CrossAnimation}
                            loop={false}
                            autoplay={true}
                            style={{ width: "80%" }}
                        />
                    </Row>
                    <Row className="justify-content-center mb-3">
                        <h2 className="text-semi-bold text-center ">
                            Something went wrong
                        </h2>
                        <p className="text-center">
                            Please try again, or call us on <b>01983 856111</b>
                        </p>
                    </Row>
                </Toast.Body>
            </Toast>

            {/* Error Toast End */}
        </>
    )
}

export default ErrorToast
